<template>
  <div class="pt-1 m-1">
    <CRow>
      <LoadingOverlay v-if="isFetchingGenericFilter" />
      <CCol md="12">
        <GenericFilter
          :basicFilterlength="0"
          :filterSchema="currentPageSchema"
          :selectedFilters="selectedFilters"
          :filterDependencyObj="filterDependencyObj"
          :isFetchingFilter="isFetchingGenericFilter"
          :callback="updateRouterURL"
          :searchCallback="searchCallback"
          :filterAction="filterAction"
        />
        <JobCount
          :totalCount="getJobCount"
          :filterCount="getFilterCount"
          :isJob="true"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12" class="mb-5">
        <CRow>
          <CCol v-for="(job, index) in getJobBoard" :key="index" sm="6" md="3">
            <div v-if="isJobBoardFetching" class="skeleton-card"></div>
            <div v-else>
              <JobCardNew :item="job" v-if="specificCustomer(job)" />
            </div>
          </CCol>
          <CCol
            class="d-flex justify-content-center"
            v-if="
              !isFetchingGenericFilter &&
                !isJobBoardFetching &&
                !getJobBoard.length
            "
          >
            <h1>No Data found!</h1>
          </CCol>
        </CRow>
        <CRow
          v-if="!noMoreDataFromJobBoard"
          class="d-flex justify-content-around align-items-center"
          style="height: 200px"
        >
          <div class="has-loading-overlay">
            <LoadingOverlay v-if="isJobBoardFetching" />
          </div>
        </CRow>
        <div
          class="d-flex justify-content-center"
          v-else-if="getJobBoard.length"
        >
          <span class="btn-pill btn-primary p-2"
            >No more records to display!</span
          >
        </div>
      </CCol>
      <!-- <PopupModal
        :modalTitle="popupModal.modalTitle"
        :modalColor="popupModal.modalColor"
        :modalContent="popupModal.modalContent"
        :modalLabel="popupModal.modalLabel"
        :isShowPopup="popupModal.isShowPopup"
        :popupModalCallBack="popupModalCallBack"
        :candidateItem="popupModal.candidateItem"
        :modalJob="popupModal.modalJob"
        :modalReasonType="popupModal.modalReasonType"
      /> -->
    </CRow>
  </div>
</template>
<script>
import CandidateCount from "@/components/Count";
import { mapGetters, mapActions } from "vuex";
import { getScope, Role, getCustomerId } from "@/helpers/helper";
// import PopupModal from "@/components/reusable/PopupModal";
import GenericFilter from "@/components/reusable/GenericFilterAndSearch/index";
import JobBoard from "../JobBoard.vue";
import JobCardNew from "@/components/jobCardNew.vue";

export default {
  extends: JobBoard,
  name: "CandidateMain",
  data() {
    return {
      currentUserRole: getScope(),
      // popupModal: {
      //   modalColor: "",
      //   modalTitle: "",
      //   modalContent: "",
      //   modalLabel: "Select Reason Code",
      //   isShowPopup: false,
      //   candidateItem: {},
      //   modalJob: "",
      //   modalReasonType: "",
      // },
    };
  },
  components: {
    JobCount: CandidateCount,
    // PopupModal,
    GenericFilter,
    JobCardNew,
  },
  computed: {
    ...mapGetters([
      "currentPageSchema",
      "isFetchingGenericFilter",
      "filterDependencyObj",
      "selectedFilters",
      "getQueryResult",
      "isSelectedFilterUpdated",
      "getRoleScopes",
    ]),
  },
  methods: {
    specificCustomer(job) {
      if (
        this.getRoleScopes.length &&
        this.getRoleScopes[0] === "system_admin"
      ) {
        return true;
      }
      if (getCustomerId() === 1) return true;
      else return job?.customer_uid == getCustomerId() ? true : false;
    },
  },
  mounted() {
    let ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    let ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>
