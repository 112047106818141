<template>
  <CCard
    v-if="item"
    class="candidate-card border-primary"
    style="min-height: 394px"
  >
    <!-- <div class="job-board-list-card cursor-pointer">
      <div class="title job-card-title d-flex justify-content-between">
        <p class="job-card-title" style="line-height:1;">{{jobTitle}}</p>
        <div v-if="isAdmins" :class="`status-${published ? 'publish' : 'draft'}`"><span>{{published ? 'Published' : 'Draft'}}</span></div>
      </div>
      <p class="posted-time">{{postedTime}}</p>
      <div class="location">
        <p>
          <CIcon name="cil-location-pin" />&nbsp;
          <span>{{comments}}</span>&nbsp;
          <div class="organisation">
            <CIcon name="cil-hospital" />&nbsp;
            <span>{{organisation}}</span>&nbsp;
          </div>
        </p>
      </div>
      <p class="content text-truncate">Our client has an urgent requirement of {{jobTitle}} in {{comments}}</p>
    </div> -->
    <div class="d-flex justify-content-between p-2 dark-block">
      <CRow>
        <CCol col="3" class="image-holder">
          <img
            :src="iconUrl"
            alt="logo"
            width="100%"
            @error="$event.target.src = '/img/hospital.png'"
            class="rounded-circle mr-1"
          />
          <CButton class="p-0 btn-link small-header" @click="navigateToJob(item)">
            Job ID : {{ id }}
          </CButton>
          <span class="text-gold ml-2"
            ><i
              v-if="featured"
              v-c-tooltip="'Featured Job'"
              style="font-size: 12px"
              class="fas fa-star"
            ></i
          ></span>
        </CCol>
        <CCol col="9">
          <h6>{{ jobTitle }}</h6>
          <p>
            <span class="icon-location"></span>
            {{ location }}
          </p>
        </CCol>
      </CRow>
    </div>
    <div class="gray-block">
      <CRow>
        <CCol v-if="organisation.length > 57" v-c-tooltip="{
          content: organisation
        }"
        class="text-truncate" lg="12">
          <span>{{ organisation }}</span>
        </CCol>
         <CCol v-else class="text-truncate" lg="12">
          <span>{{ organisation }}</span>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="6">'
          <div v-if="item.status == 'Active'" class="status" style="color: green">
            <i class="fas fa-check-circle"></i>
            {{ publishStatus }}
          </div>
          <div v-else-if="item.status == 'Inactive'" class="status" style="color: red">
            <i class="fas fa-times-circle"></i>
            {{ publishStatus }}
          </div>
          <div v-else-if="item.status == 'Pending'" class="status" style="color: orange">
            <i class="fas fa-business-time"></i>
            {{ publishStatus }}
          </div>
          <div v-else-if="item.status == 'Draft'" class="status" style="color: grey">
            <i class="fas fa-archive"></i>
            {{ publishStatus }}
          </div>
        </CCol>
        <CCol lg="6" style="text-align: right">
          <div class="status">Expire: {{ expiryDays }}</div>
        </CCol>
      </CRow>
    </div>

    <CCardBody class="content-block">
      <div class="d-flex flex-column justify-content-between">
        <div class="details">
          <CRow>
            <CCol class="lable" col="5"> Type </CCol>
            <CCol class="value" col="7">
              {{ type }}
            </CCol>
          </CRow>

          <CRow>
            <CCol class="lable" col="5"> Level </CCol>
            <CCol v-if="level.length > 27" 
              v-c-tooltip="{
                content: level,
              }" 
              class="value text-truncate" 
              col="7"
            >
              {{ level }}
            </CCol>
            <CCol v-else class="value text-truncate" col="7">
              {{ level }}
            </CCol>
          </CRow>

          <CRow>
            <CCol class="lable" col="5"> Speciality </CCol>
            <CCol             
              v-if="isWrapped(speciality)"
              class="value text-truncate"
              col="7"
              v-c-tooltip="{
                content: speciality,
              }"
            >
              {{ speciality }}
            </CCol>
            <CCol v-else class="value" col="7">
              {{ speciality }}
            </CCol>
          </CRow>

          <CRow v-if="!isDentist">
            <CCol class="lable" col="5" v-if="isAHP">
              {{ isAHP ? `Sub Type/License` : `Sub Speciality` }}</CCol
            >
            <CCol class="lable" col="5" v-else>
              {{ isNurse ? `Other Expertise` : `Sub Speciality` }}</CCol
            >
            <CCol
              v-if="isWrapped(subSpeciality)"
              class="value text-truncate"
              col="7"
              v-c-tooltip="{
                content: subSpeciality,
              }"
            >
              {{ subSpeciality }}
            </CCol>
            <CCol v-else class="value" col="7">
              {{ subSpeciality }}
            </CCol>
          </CRow>
        </div>

        <CRow class="content-center text-muted">
          <CCol lg="6">
            <div class="card-block">
              <a @click="navigateToJobListPreview(item)" class="text-bold">
                <span class="match-span">{{ appliedCount }}</span>
              </a>
              <p class="m-0">Applied</p>
            </div>
          </CCol>
          <CCol lg="6">
            <div class="card-block">
              <a @click="navigateToJobListPreview(item)" class="text-bold">
                <span class="match-span">{{ shortlistCount }}</span>
              </a>
              <p class="m-0">Shortlisted</p>
            </div>
          </CCol>
          <CCol lg="6">
            <div class="card-block">
              <a @click="navigateToJobListPreview(item)" class="text-bold">
                <span class="match-span">{{ interviewCount }}</span>
              </a>
              <p class="m-0">Interview</p>
            </div>
          </CCol>
          <CCol lg="6">
            <div class="card-block">
              <a
                @click="navigateToJobListPreview(item)"
                class="text-bold"
              >
              <span class="match-span">{{ recruitCount }}</span>
              </a>
              <p class="m-0">Recruited</p>
            </div>
          </CCol>
        </CRow>
      </div>
    </CCardBody>

    <div class="d-flex justify-content-center pb-2">
      <CButton
        color="primary"
        variant="outline"
        class="mx-2 action-btn"
        @click="previewJob(item)"
      >
        Preview Job</CButton
      >
      <CButton
        v-if="!item.doh_candidate_status"
        color="primary"
        variant="outline"
        class="mx-2 action-btn"
        @click="navigateToJobListPreview(item)"
      >
        View Job</CButton
      >
    </div>
    <JobCardPreviewModal
      v-if="previewModal"
      :isShowPopup="previewModal"
      :onCancelPreview="onCancelPreview"
    />
  </CCard>
</template>


<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import JobCardPreviewModal from "@/components/jobCardPreviewModal";

export default {
  props: ["item"],
  components: {
    JobCardPreviewModal,
  },
  data() {
    return {
      previewModal: false,
      wrapMaxLength: 27,
    };
  },
  computed: {
    ...mapGetters(["getRoleScopes"]),
    jobTitle() {
      return this.item?.job_title || "--";
    },
    id() {
      return `${this.item?.job_id}` || "--";
    },
    level() {
      return this.item.level_name ? this.item.level_name : "--";
    },
    organisation() {
      return this.item?.organisation_name || "--";
    },
    location() {
      return this.item?.location_name || "--";
    },
    type() {
      return this.item?.candidate_type_name || "--";
    },
    speciality() {
      return this.item?.speciality_name || "--";
    },
    subSpeciality() {
      return this.item?.sub_speciality_name || "--";
    },
    featured() {
      return this.item?.featured || false;
    },
    publishStatus() {
      return this.item?.status == "Active"
        ? "Published"
        : this.item?.status;
    },
    expiryDays() {
      let days = this.item.expiry_review_date
        ? this.expiryDate(this.item.expiry_review_date) < 0
          ? "Already Expired"
          : this.expiryDate(this.item.expiry_review_date) + "\xa0" + "Days"
        : 0;
      return days;
    },
    iconUrl() {
      return this.item?.organisation_logo_url
        ? window.location.origin + this.item?.organisation_logo_url
        : "/img/hospital.png";
    },
    appliedCount() {
      return this.item?.counts?.applied_count ? this.item.counts.applied_count : 0;
    },
    shortlistCount() {
      return this.item?.counts?.short_list_count ? this.item.counts.short_list_count : 0;
    },
    recruitCount() {
      return this.item?.counts?.recruit_count ? this.item.counts.recruit_count : 0;
    },
    interviewCount() {
      return this.item?.counts?.interview_count ? this.item.counts.interview_count : 0;
    },
        isNurse() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 2
        : false;
    },
    isAHP() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 3
        : false;
    },
    // postedTime(){
    //   let created = moment(this.item?.created_on)
    //   let today = moment()
    //     return today.diff(created, 'days') !== 0 ? `${today.diff(created, 'days')} days ago`: 'New';
    // }
    isDentist() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id == 4
        : false;
    },
  },
  methods: {
    ...mapActions(["selectedJobBoard"]),
    isWrapped(data) {
      return data?.length > this.wrapMaxLength || false;
    },
    navigateToJobListPreview(item) {
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL",fullPath);
      this.$router.push({ path: `/job-list-preview/${item?.job_uuid}` });
    },
    navigateToJob(item) {
      this.$router.push({ path: `/jobs-list?job_uuid=${item?.job_uuid}` });
    },
    expiryDate(expiryDate) {
      let expiration = moment(expiryDate);
      let current_date = moment();
      return expiration.diff(current_date, "days");
    },
    previewJob(job) {
      this.selectedJobBoard(job.job_uuid);
      this.previewModal = true;
    },
    onCancelPreview() {
      this.previewModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.job-card-title {
  margin-bottom: 4px;
}
.table-card {
  text-align: center;
  min-width: 75px;
  max-width: 75px;
  padding: 3px;
  padding-top: 10px;
  height: 60px;
  margin-right: 2px;
  border-style: solid;
  border-width: thin;
  font-weight: 400;
  font-size: 12px;
  a {
    cursor: pointer;
    color: gray;
  }
}
.match-span {
  color: #085D66E6;
}
.text-bold {
  font-weight: 700;
}
.icon-wrapper {
  .icon {
    width: 50px;
    img {
      width: 35px;
      padding: 5px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.tooltip-css {
  tooltip {
    white-space: nowrap;
  }
}
.action-btn {
  width: 100%;
  text-transform: uppercase;
}
.cursor-pointer {
  cursor: pointer;
}
.text-gold {
  color: #ff7600;
}
</style>
